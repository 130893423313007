.background {
	background-image: url('https://res.cloudinary.com/das8diykm/image/upload/g_auto/f_auto/q_auto/stitching/paper.jpg');
	background-position: center;
  min-height: 100vh;
  padding: 3%;
  justify-content: space-between;
	display: flex;
	flex: auto;
	flex-direction: column;
}

h1.title {
	display: flex; 
  margin-bottom: 0.5em;
	margin-top: 0px;
  font-size: 40px;
  line-height: 1.23;
	text-transform: uppercase;
	align-items: center;
	font-family: 'Josefin Sans';
	font-weight: 300;
}

button.back {
	margin-right: 15px;
	margin-bottom: 8px;
	display: flex;
	background-color: var(--WHITELITE);
	border-radius: 50%;
	border: 1px solid var(--WHITE);
	height: 2em;
	width: 2em;
	color: -internal-light-dark(black, white);
	&:hover{
		background-color: var(--WHITE);
	}
	svg {
		overflow: visible;
		z-index: 3;
	}
}

.craft {
	position: relative;
	height: auto;
	margin-right: 0;
	margin-left: 0;
	zoom: 1;
	display: flex;
	&:before{
		display: table;
    content: "";
	}
	&:after {
		display: table;
    content: "";
		clear: both;
	}
}

div.list-view {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 33%;
	flex: 0 0 auto;
	float: left;
	position: relative;
  padding: 0px;
}

button.btn-preview {
	box-shadow: 2px 2px 15px 6px;
	border-radius: 50%;
	width: 100%;
	height: fit-content;
	padding: 0px;
	border: none;
	margin-left: 10px;
	position: absolute;
	max-width: 300px;
	-webkit-appearance: button;
	box-sizing: border-box;
	color: rgba(0,0,0,.65);
	background-color: transparent;
	transition: all .3s cubic-bezier(.645,.045,.355,1);
	font-size: 14px;
	outline: 0;
	line-height: 1.499;
	display: inline-block;
	font-weight: 400;
	white-space: nowrap;
	text-align: center;
	background-image: none;
	cursor: pointer;
	&:hover{
    color: #fff;
		background: #fff;
		background-color: transparent;
	}
}

img.preview {
	width: 100%; 
	max-width: 300px; 
	vertical-align: middle;
  border-style: none;
}

div.view {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-left: 3%;
	box-sizing: border-box;
	width: 66%;
	flex: 0 0 auto;
	float: left;
	position: relative;
	padding-right: 0;
}

button.flip {
	display: flex;
  position: absolute;
  top: 0px;
  right: 100px;
  align-items: center;
	padding: 8px 10px 5px 14px;
	background: var(--WHITELITE);
	border: 1px solid var(--WHITE);
	font-family: 'Josefin Sans';
	font-weight: 400;
	color: #000000ba;
	background-color: #ffffffe8;
	svg {
		margin-right: 5px;
		margin-bottom: 4px;
	}
	&.sm {
		position: relative;
		top: unset;
		right: unset;
		border: none;
	}
}

div.img-wrap-lg {
	box-shadow: 2px 2px 15px 6px;
	border-radius: 50%;
	width: 100%;
	height: fit-content;
	max-width: 600px;
	margin-bottom: 20px;
	box-sizing: border-box;
	display: flex;
	background-color: transparent;
}

img.hoop-img {
	width: 100%; 
	max-width: 600px;
	&.blur {
		filter: blur(10px);
		transition: opacity ease-in 1000ms;
		position: absolute;
		opacity: 0;
		display: none;
	}
	&.loading {
		opacity: 1;
		display: flex;
	}
}

h2.hoop-name {
	font-weight: 400;
	font-size: 24px;
	margin-bottom: 0.5em;
	line-height: 1.35;
	letter-spacing: 1px;
	font-family: 'Josefin Sans';
	&.sm {
		margin-left: 15px;
		margin-bottom: 0px;
		margin-top: 0px;
		max-height: 65px;
	}
}

span.light {
	font-weight: 300;
	margin-left: 15px;
	font-size: 20px;
	&.sm {
		margin-left: 0px;
	}
}

div.bottom-row {
	margin-top: auto;
	justify-content: flex-end;
	display: flex;
	flex-direction: row;
}

div.loader {
	display: block;
	position: fixed;
	margin-left: 15%;
	margin-top: 15%;
	border: 10px solid #f3f3f340;
	border-top: 10px solid var(--WHITE);
	border-radius: 50%;
	width: 60px;
	height: 60px;
	animation: spin 1s linear infinite;
	&.black {
		border: 10px solid var(--XLITEBLACK);
		border-top: 10px solid var(--BLACK);
		margin-left: 40%;
		margin-top: -15%;
	}

}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

div.hide {
	display: none;
}

// Small
div.sm-list {
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;
	margin-bottom: 15;
}

button.sm-preview {
	border-radius: 50%;
	width: 100%;
	height: fit-content;
	padding: 0;
	border: none;
	margin: 5px;
	max-width: 150px;
	box-shadow: 2px 2px 5px 2px;
	-webkit-appearance: button;
	box-sizing: border-box;
	color: rgba(0,0,0,.65);
	background-color: transparent;
	transition: all .3s cubic-bezier(.645,.045,.355,1);
	font-size: 14px;
	outline: 0;
	line-height: 1.499;
}

img.sm-preview {
	width: 100%;
	max-width: 350px;
}

.sm-text {
	display: flex;
	font-family: 'Josefin Sans', sans-serif;
	color: var(--LITEBLACK);
	align-items: 'center';
}

div.modal {
	min-height: 50vh;
	background-color: #ffffffe8;
	width: 98%;
	position: absolute;
	top: 12%;
	height: 85%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 15px 0px;
}

section.sm-hoop {
	border-radius: 50%;
	width: 100%;
	max-width: 600;
	height: fit-content;
	margin-bottom: 20;
	box-shadow: 2px 2px 5px 2px;
	&.loading {
		box-shadow: none;
	}
}

img.sm-hoop {
	width: 100%;
	max-width: 600px; 
	display: block;
	&.loading {
		display: none;
	}
}

div.modal-row {
	display: flex;
	justify-content: space-between;
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 32px;
}

button.close-modal {
	position: absolute;
	right: 0;
	top: 5px;
	color: var(--XLITEBLACK);
	font-size: 20px;
	font-weight: 400;
	font-family: 'Josefin Sans';
	background: transparent;
}

button.move {
	background-color: #ffffffe8;
}