div.layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: var(--BLACK);
}

div.full-bg {
  background-position: center;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  background-image: url('https://images.ctfassets.net/hkpf2qd2vxgx/7uBnRmVd5qt1ZqTjEj1ZF8/a3e8bb245c23bd9bd119faa9214f69dc/campus-8.jpg_w_1952');
  position: absolute;
  display: flex;
  &#blur {
    filter: blur(5px);
    transition: filter 1s cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-transition: filter 1s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
}

div.home-menu {
  position: absolute;
  bottom: 25px;
  width: 100%;
  height: auto;
  padding: 0px 25px;
  zoom: 1;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

h1.home-title {
  text-transform: uppercase;
  letter-spacing: 3px;
  color: var(--WHITE);
  font-family: 'Paytone One';
  margin-bottom: 0.5em;
  font-size: 38px;
  line-height: 1.23;
}

p.home-text {
  color: var(--WHITE);
  letter-spacing: 4px;
  font-size: 12px;
  font-weight: 700;
  line-height: 2;
}

button.menu-item {
  height: 10%;
  width: 23%;
  min-width: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: none;
  transition: background-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-transition: background-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  cursor: pointer;
  font-family: 'Paytone One';
	color: var(--WHITE);
  font-size: 3vh;
  text-transform: uppercase;
  margin: 5px 0px;
  &#about {
    background-color: var(--PINK);
    &:hover {
      background-color: var(--PINKLITE);
    }
  }
  &#experience {
    background-color: var(--BLUE);
    &:hover {
      background-color: var(--BLUELITE);
    }
  }
  &#projects {
    background-color: var(--PEACH);
    &:hover {
      background-color: var(--PEACHLITE);
    }
  }
  &#contact {
    background-color: var(--AQUA);
    &:hover {
      background-color: var(--AQUALITE);
    }
  }
}

button.stitch-link{
  position: absolute;
  z-index: 3;
  right: 0px;
  background-color: transparent;
  font-size: 20px;
  color: var(--WHITE);
  border: none;
  cursor: pointer;
}

g#heart{
  fill: var(--WHITE);
  &:hover{
    fill: var(--ERRORDARK);
  }
}

// shared
div.container {
  height: 80%;
  max-height: 900px;
  width: 97%;
  max-width: 1200px;
  position: absolute;
  border-radius: 4px;
  background: var(--WHITE);
  margin: 2%;
  border-width: 2vw;
  border-style: solid;
  padding: 2%;
  overflow-x: clip;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  scrollbar-gutter: stable;
  &#about {
    border-color: var(--PINK);
  }
  &#experience {
    border-color: var(--BLUE);
  }
  &#projects {
    border-color: var(--PEACH);
  }
  &#contact {
    border-color: var(--AQUA);
  }
}

h1.headline {
  letter-spacing: 3px;
  font-family: 'Paytone One';
  margin-bottom: 0.5em;
  margin-top: 2%;
  font-size: 3vh;
  line-height: 1.23;
  &#right {
    text-align: end;
    margin-bottom: 0px;
    margin-top: 0.5em;
  }
  &.pink{
    color: var(--PINK);
  }
  &.blue{
    color: var(--BLUE);
    &#second {
      margin-top: 1em;
    }
  }
  &.peach {
    color: var(--PEACH);
  }
  &.aqua {
    color: var(--AQUA);
    margin-bottom: 25px;
  
  }
}

div.button-row {
  display: flex;
  width: auto;
  flex-wrap: wrap;
  margin-top: 60px;
  &.close {
    margin-top: 25px;
  }
  button.sm{
    background-color: var(--BLUELITE);
    height: 40px;
    width: 150px;
    color: var(--WHITE);
    font-size: 15px;
    text-transform: uppercase;
    &.experience-button{
      background-color: var(--BLUELITE);
      &:hover {
        background-color: var(--BLUE);
      }
    }
    &.github-button{
      background-color: var(--PEACHLITE);
      &:hover {
        background-color: var(--PEACH);
      }
    }
    &#left {
      margin-right: 15px;
    }
    &.send {
      background-color: var(--AQUA);
      &:hover {
        background-color: var(--AQUALITE);
      }
    }
    
  }
}

