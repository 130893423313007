// about
div.wrap {
  width: 100%;
  max-width: 850px;
  margin: 2%;
  height: fit-content;
}

div.comic-row {
  display: flex;
  align-items: center;
  margin: 25px 0px;
  &#bottom {
    align-items: end;
  }
}

img.comic {
  border: 3px solid var(--LITEBLACK);
  border-radius: 4px;
  object-fit: cover;
  height: 80px;
  margin: 0px 10px;
  &#bottom {
    margin-top: 0.5em;
  }
}

// experience
img.icon {
  width: 30px; 
  height: 30px;
  margin: auto;
  filter: grayscale(1);
}

div.icon-row {
  display: flex;
}

div.card {
  display: flex;
  flex-direction: column;
  margin: 0px 5px;
  width: 50px;
}

p.caption {
  font-size: 10px;
  text-align: center;
  margin-bottom: 0px;
  color: var(--LITEBLACK);
}

div.skill-row {
  display: flex;
  width: auto;
  flex-wrap: wrap;
  align-items: center;
}

div.column#title {
  width: 40%;
  min-width: 300px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

div.exp-item {
  display: flex;
  flex-wrap: wrap;
  line-height: 0.5;
}

h2 {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0px;
  &.role{
    color: var(--BLACK);
    width: 300px;
    margin-right: 10px;
  }
  &.company{
    color: var(--LITEBLACK);
  }
}

h3 {
  font-style: italic;
  color: var(--LITEBLACK);
  font-size: 14px;
  font-weight: 500;
  &.time {
    width: 300px;
    margin-right: 10px;
    margin-block-start: 1em;
    margin-block-end: 0em;
  }
}

div.specifics{
  margin-top: 35px;
}

p.sm-headline {
  &.blue {
    letter-spacing: 1px;
    font-family: 'Paytone One';
    margin-bottom: 0.5em;
    font-size: 2vh;
    line-height: 1.23;
    color: var(--BLUELITE);
    width: 150px;
    margin-bottom: 0px;
  }
}

// projects
div.projects {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

h2.project-name {
  letter-spacing: 2px;
  font-family: 'Paytone One';
  margin-bottom: 0.5em;
  font-size: 2.5vh;
  line-height: 1.23;
  color: var(--PEACHLITE);
  &:hover {
    color: var(--PEACH);
  }
}

div.project-card {
  width: 400px;
}

// contact
div.contact {
  max-width: 840px;
  margin: 2%;
  height: fit-content;
}

div.contact-form {
  display: flex;
  flex-direction: column;
}

.form-item {
  border-radius: 4px;
  width: 100%;
  max-width: 400px;
  margin: 5px 0px;
  border: 1px solid var(--AQUA);
  padding: 5px;
  color: var(--LITEBLACK);
  letter-spacing: 1px;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.5;
  &.text {
    height: 35px;
  }
  &.area {
    height: 80px;
    font-family: var(--BASEFONT);
  }
  &.red {
    border: 1px solid var(--ERRORDARK);
  }
  &:focus-visible {
    outline: none;
  }
}
textarea::placeholder {
  font-family: var(--BASEFONT);
  color: var(--XLITEBLACK);
  letter-spacing: 1px;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.5;
}

::placeholder {
  color: var(--XLITEBLACK);
  letter-spacing: 1px;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.5;
}
:-ms-input-placeholder {
  color: var(--XLITEBLACK);
  letter-spacing: 1px;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.5;
}

div.status {
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 12px;
  margin: 15px 0px;
  text-align: center;
  &.error {
    background-color: var(--ERRORLITE);
  }
  &.success {
    background-color: var(--AQUA);
  }
}

h4.status {
  font-family: 'Paytone One';
  margin-bottom: 0px;
  margin-top: 10px;
  &.error{
    color: var(--ERRORDARK);
    font-size: 20px;
  }
  &.success {
    color: var(--WHITE);
    font-size: 25px;
  }
}

p.status-text {
  font-size: 13px;
}